@import '~normalize.css/normalize.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/select/lib/css/blueprint-select.css';
//@import '~@blueprintjs/table/lib/css/table.css';
@import "~@blueprintjs/core/lib/scss/variables";

//$app-width: 1280px;
//$app-width: 98%;
$app-width-min: 100%;
$app-margin-v: 0px;
$app-margin-h: 8px;
$column-gap: 8px;


@media screen
  and (max-device-width: 640px){
    $app-width: 98%;
    $app-width-min: 390px;
    $app-margin-v: 0px;
    $app-margin-h: 8px;
    $column-gap: 8px;

}


@media screen
  and (max-device-width: 1024px){
    $app-width: 98%;
    $app-width-min: 1024px;
    $app-margin-v: 0px;
    $app-margin-h: 8px;
    $column-gap: 8px;

}


body {
  background-color: $pt-app-background-color;
}

.App {
  text-align: center;
  //width: $app-width;
  //min-width: $app-width-min;
  min-width: 80%;
  margin: 0 auto;
}


.AppLogo {
  width: 24px;
  height: 24px;
}

@for $i from 1 through 5 {
  .Spacing-V-#{$i*8} {
    height: $i*8px
  }
}

.AppBody {
  text-align: left;
  margin: $app-margin-v $app-margin-h;
}

// 13 columns grid
.Row {
  display: grid;
  column-gap: $column-gap;
  grid-template-columns: repeat(13, 1fr);
}
@for $i from 1 through 13 {
  @for $s from 1 through 13-$i+1 {
    .Col-#{$i}-#{$s} {
      grid-column: $i / span $s;
    }
    .Col2-#{$i}-#{$s} {
      grid-column: $i / span $s;
    }
    .Col3-#{$i}-#{$s} {
      grid-column: $i / span $s;
    }
  }
}
.Col-H {
  display: flex;
  flex-direction: row;
}
.Col-H > *:not(:first-child) {
  margin-left: $column-gap;
}


.Button-LabeledFormGroup {
  margin-top: 22px;
  margin-bottom: 16px;
}

.Checkbox-LabeledFormGroup {
  margin-top: 28px;
  margin-bottom: 16px;
}

.Input-XS {
  width: 45px;
}

.TCol-ACCode {
  width: 100px;
}
.TCol-ACName {
  width: 300px;
}
.TCol-IDNo {
  width: 150px;
}
.TCol-Tel {
  width: 150px;
}
.TCol-AECode {
  width: 100px;
}
.TCol-Status {
  width: 60px;
}

.MenuIcon {
  width: 16px;
  height: 16px;
}
.MenuTile {
  text-align: center;
  font-weight: bold;
}
.MenuTile:hover {
  transform: scale(1.1);
}
.MenuTileImage {
  width: 100%;
  height: 100%;
}

@media print {
  .No-Print {
    display: none !important;
  }

  .Print-Align-C {
    margin: auto;
  }

  .Print-Align-C a {
    color: #182026;
  }

  .Print-Align-C td {
    padding: 3px 11px !important;
  }

  .Print-90-Percent * {
    font-size: 90% !important;
  }
  
  * {
      -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
      color-adjust: exact !important;  /*Firefox*/
  }

  .Page-Break-Before {
    page-break-before: always;
  }
  .Page-Break-After {
    page-break-after: always;
  }
}

@media screen {
  .No-Screen {
    display: none !important;
  }
}
